import React from 'react';

import { Link } from 'gatsby';

import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';

const date = new Date().getFullYear();

export default () =>
  <div className="black-background container-fluid border-top border-dark pb-5 pt-3">
    <div className="row justify-content-around px-5">
      <div className="col-12 col-md-2 d-flex flex-column text-center text-md-left">
        <Link to="/" className="text-white sans-serif logo-font text-nowrap">
          VANCOUVER JADE
        </Link>
        <div className="mt-4 mt-md-5">
          <a href="https://www.facebook.com/vancouverjadee/" title="Vancouver Jade Facebook" target="_blank" rel="noopener noreferrer" className="text-white d-inline-block px-3 px-md-0 d-md-block py-0 py-md-3"><h4><FaFacebookF className="social-media-link border rounded-circle p-1"/></h4></a>
          <a href="https://www.instagram.com/vancouverjade/?hl=en" title="Vancouver Jade Instagram" target="_blank" rel="noopener noreferrer" className="text-white d-inline-block px-3 px-md-0 d-md-block py-0 py-md-3"><h4><FaInstagram className="social-media-link border rounded-circle p-1"/></h4></a>
        </div>
      </div>
      <div className="col-12 col-md-5 mt-4 mt-md-5 mb-5 text-center">
        <h4 className="text-white serif">Get in Touch</h4>
        <hr className="col-1 horizontal-divider my-0"></hr>
        <div className="my-3">
          <h6 className="text-white small sans-serif">Would you like to learn more about one of our products?</h6>
          <h6 className="text-white small sans-serif">Do you have any upcoming projects that you would like to discuss?</h6>
          <h6 className="text-white small sans-serif">We'd love to hear from you.</h6>
        </div>
        <Link to="contact"><button className="btn btn-outline-light btn-sm thick-border rounded-0 py-1 px-5 sans-serif">CONTACT US</button></Link>
      </div>
      <div className="col-12 col-md-2 d-flex flex-md-column justify-content-around text-center text-md-right mt-0 mt-md-5 mb-0 mb-md-5">
        <Link to="faqs" className="sans-serif text-white default-links mx-2 mx-md-0 pb-2 small">FAQs</Link>
        <Link to="sitemap" className="sans-serif text-white default-links mx-2 mx-md-0 pb-2 small text-nowrap">Site Map</Link>
        <Link to="about-us" className="sans-serif text-white default-links mx-2 mx-md-0 pb-2 small text-nowrap">About Us</Link>
        <Link to="privacy-policy" className="sans-serif text-white default-links mx-2 mx-md-0 pb-2 small text-nowrap">Privacy Policy</Link>
      </div>
    </div>
    <div className="row justify-content-center mt-2">
      <div className="col-9 border-top border-dark px-0">
        <h6 className="sans-serif small text-secondary mt-1">Copyright &#xa9; Vancouver Jade {date}</h6>
      </div>
      <div className="col-1 border-top border-dark"></div>
    </div>
  </div>