import React from 'react';

import { Helmet } from "react-helmet";

import metaImage from '../../static/assets/nephrite-jade-gemstone-slabs.jpg';


export default (props) => 
<Helmet>
  <html lang="en" />
  <meta charSet="utf-8" />

  {/* Primary Meta Tags */}
  <title>Vancouver Jade { props.title ? '| ' + props.title : ''} </title>
  <meta name="title" content="Vancouver Jade" />
  <meta name="description" content="Gemstone slabs proudly sourced and manufactured in British Columbia." />

  {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://vancouver-jade.netlify.com/" />
  <meta property="og:title" content="Vancouver Jade" />
  <meta property="og:description" content="Gemstone slabs proudly sourced and manufactured in British Columbia." />
  <meta property="og:image" content={'https://vancouver-jade.netlify.com' + metaImage} />

  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:url" content="https://vancouver-jade.netlify.com/" />
  <meta property="twitter:title" content="Vancouver Jade" />
  <meta property="twitter:description" content="Gemstone slabs proudly sourced and manufactured in British Columbia." />
  <meta property="twitter:image" content={'https://vancouver-jade.netlify.com' + metaImage} />

</Helmet>