import React from 'react';

import {Link, StaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';

import {FaFacebookF} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import {IoIosArrowDown} from 'react-icons/io';
import {IoIosArrowUp} from 'react-icons/io';

class HeaderWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      darkFont: props.darkFont,
      displayMenu: false,
      displayExtendedMenu: false,
      location: ''
    }

    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      location: window.location.pathname
    });
  };

  handleMenuClick = (display) => {
    if (display === false) {
      this.setState({
        startClosingAnimation: true
      });
      setTimeout(() => {
        this.setState({
          startClosingAnimation: false,
          displayMenu: display,
        })
      }, 200);
    } else {
      this.setState({
        displayMenu: display
      })
    }
  };

  handleProductsMenuClick = (display) => {
    display = !display;
    this.setState({
      displayExtendedMenu: display
    });
  }

  render() {
    return(
      <Header darkFont={this.state.darkFont} startClosingAnimation={this.state.startClosingAnimation} displayMenu={this.state.displayMenu} location={this.state.location} menuClick={this.handleMenuClick} displayExtendedMenu={this.state.displayExtendedMenu} handleProductsMenuClick={this.handleProductsMenuClick} ></Header>
    )
  }
}

export default HeaderWrapper;

const Header = (props) => (
  <StaticQuery 
    query={graphql`
        query {
          logoDarkFont: file(relativePath: { regex: "/^logo-only-black.png$/" }) {
            childImageSharp {
              fixed(width:100, quality:90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          },
          logoWhiteFont: file(relativePath: { regex: "/^logo-only-white.png$/" }) {
            childImageSharp {
              fixed(width:100, quality:90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          },
        }
    `}
    render={(data) => {
      // let location = window.location.pathname;
      const linkColor = props.darkFont ? 'text-dark' : 'text-white';
      return (
        // NAV
        <div className="container-fluid p-5 nav-placement">
          <div className="row justify-content-center align-items-center">
            <div className="col-3 col-md-4 text-left pl-0 pl-lg-5">
              <Link className={`sans-serif logo-font d-none d-md-block pl-0 pl-lg-5 ${linkColor}`} to="/">VANCOUVER JADE</Link>
              <span className={`d-md-none ${props.location === '/' ? 'd-none' : 'd-block'}`} >&nbsp;</span>
            </div>
            <div className={`col-md-4 text-center ${props.location === '/' ?  'col-12' : 'col-6'}`}>
              <Link to="/">
                <Img fixed={props.darkFont ? data.logoDarkFont.childImageSharp.fixed : data.logoWhiteFont.childImageSharp.fixed} alt="Vancouver Jade" />
                <p className={`d-md-none mt-n4 sans-serif logo-font text-nowrap ${linkColor} ${props.location === '/' ? 'd-block' : 'd-none'}`}>VANCOUVER JADE</p>
              </Link>
            </div>
            <div className="col-3 col-md-4 text-right pr-0 pr-lg-5">
              <p onClick={() => {props.menuClick(true)}} className={`d-md-block mb-0 sans-serif cursor-pointer pr-0 pr-lg-5 ${linkColor} ${props.location === '/' ? 'd-none' : 'd-block'}`}><span className="d-none d-md-inline" >Menu</span> <span className="align-middle ml-2 h2">=</span></p>
            </div>
          </div>

          {/* MENU */}
          {props.displayMenu ? 
            <div className={`position-fixed black-background menu-container ${props.startClosingAnimation === true ? 'fade-out' : '' }`}>
              
              <div className="container-fluid p-5">
                <div className="row justify-content-center align-items-center px-0 px-lg-5">
                  <div className="col-3 col-md-4 text-left pl-0 pl-lg-5">
                    <Link className={`sans-serif logo-font d-none d-md-block text-light`} to="/">VANCOUVER JADE</Link>
                    <span className={`d-md-none ${props.location === '/' ? 'd-none' : 'd-block'}`} >&nbsp;</span>
                  </div>
                  <div className={`col-md-4 text-center ${props.location === '/' ?  'col-12' : 'col-6'}`}>
                    <Link to="/">
                      <Img fixed={data.logoWhiteFont.childImageSharp.fixed} alt="Vancouver Jade" />
                      <p className={`d-md-none mt-n4 sans-serif logo-font text-nowrap text-light ${props.location === '/' ? 'd-block' : 'd-none'}`}>VANCOUVER JADE</p>
                    </Link>
                  </div>
                  <div className="col-3 col-md-4 text-right pr-0 pr-lg-5">
                    <p onClick={() => {props.menuClick(false)}} className={`d-md-block mb-0 sans-serif text-light cursor-pointer ${props.location === '/' ? 'd-none' : 'd-block'}`}><span className="d-none d-md-inline" >Close</span> <span className="align-baseline ml-2 h5">x</span></p>
                  </div>
                </div>
                <div className="row d-flex flex-column align-items-center">
                  <Link to="/about-us" className="h4 text-light serif my-3 font-weight-light" >About Us</Link>

                  <div className="h4 text-light serif mt-3 mb-0 cursor-pointer font-weight-light" onClick={() => {props.handleProductsMenuClick(props.displayExtendedMenu)}}>Products</div>
                  {props.displayExtendedMenu ?
                    <IoIosArrowUp className="mb-2 green-accent small"/>
                    :
                    <IoIosArrowDown className="mb-2 green-accent small"/>
                  }
                  {props.displayExtendedMenu ? 
                    <div className="d-flex flex-column text-center">
                      <hr className="col-1 horizontal-divider"></hr>
                      <Link to="/products/slabs-and-surfaces" className="h5 text-light serif mt-0 mb-2">Slabs and Surfaces</Link>
                      <Link to="/products/feature-walls" className="h5 text-light serif my-2">Feature Walls</Link>
                      <Link to="products/gemstone" className="h5 text-light serif mb-0 mt-2">Gemstone</Link>
                      <hr className="col-1 horizontal-divider"></hr>
                    </div>
                    : null
                  }

                  <Link to="/contact" className="h4 text-light serif my-3 font-weight-light" >Contact Us</Link>
                  <Link to="/faqs" className="h4 text-light serif my-3 font-weight-light" >FAQs</Link>
                  <Link to="/privacy-policy" className="h4 text-light serif my-3 font-weight-light" >Privacy Policy</Link>
                  <Link to="/sitemap" className="h4 text-light serif my-3 font-weight-light" >Sitemap</Link>
                </div>
                <div className="row mt-3 d-flex justify-content-center">
                  <a href="https://www.facebook.com/vancouverjadee/" title="Vancouver Jade Facebook" target="_blank" rel="noopener noreferrer" className="mr-4" >
                    <h1 className="text-white"><FaFacebookF className="social-media-link border rounded-circle p-2"/></h1>
                  </a>
                  <a href="https://www.instagram.com/vancouverjade/?hl=en" title="Vancouver Jade Instagram" target="_blank" rel="noopener noreferrer" className="ml-4" >
                    <h1 className="text-white"><FaInstagram className="social-media-link border rounded-circle p-2"/></h1>
                  </a>
                </div>
              </div>

            </div> 
            : null 
          }
       </div>
      )
    }}
  />
)

// export default Header;