import React from "react";

import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import { GlassMagnifier, TOUCH_ACTIVATION } from "react-image-magnifiers";
import ReactCompareImage from 'react-compare-image';

import hoverBase from '../../../static/assets/hover-base.png';
import hoverImage from '../../../static/assets/hover-image.png';
import slideImageLeft from '../../../static/assets/12x12-nephrite-jade-tile-natural.jpg';
import slideImageRight from '../../../static/assets/12x12-nephrite-jade-tile-backlit.jpg';

import {IoIosAddCircle} from 'react-icons/io';
import {MdSwapVerticalCircle} from 'react-icons/md';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Meta from '../../components/meta';

export const query = graphql`
  query {
    slabJumbotron: file(relativePath: { regex: "/^nephrite-jade-gemstone-slabs.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    productSpecSlab: file(relativePath: { regex: "/^pro-spec-slab.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

class SlabsAndSurfaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'dimensions',
      under576: false
    }

    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    if (window.innerWidth < 576 && !this.state.under576) {
      this.setState({under576: true});
    } else if (window.innerWidth > 576 && this.state.under576) {
      this.setState({under576: false});
    }
  }

  handleCategoryClick = (category) => {
    this.setState({
      tab: category
    })
  };

  render() {
    return(
      <div className="vh-100">
        <Meta title="Slabs &amp; Surfaces" />
        <Header darkFont={false}/>

        <BackgroundImage className="p-5 container-fluid black-background slab-jumbotron" fluid={this.props.data.slabJumbotron.childImageSharp.fluid} >
          <div className="row justify-content-center my-5">
            <div className="col-12 text-center mt-5 pt-0 pt-md-5">
              <p className="sans-serif text-white mt-5">Products</p>              
              { this.state.under576 ? 
                <h1 className="text-white serif d-inline-block">JADE SLABS AND SURFACES</h1>
                :
                <h1 className="text-white serif display-4 d-inline-block">JADE SLABS AND SURFACES</h1>
              }
              <hr className="col-1 horizontal-divider my-0"></hr>
              <h5 className="serif text-white mt-3 font-italic">Nephrite gemstone showcased in it’s most natural form.</h5>
            </div>
          </div>
        </BackgroundImage>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            
            <h1 className="col-12 col-lg-10 order-2 order-md-1 text-center text-md-left text-dark serif d-inline-block px-0 px-sm-3 pt-2 pt-md-5">INTRODUCTION</h1>
            <div className="col-12 col-md-6 col-lg-5 order-3 order-md-2 px-0 px-md-3 pb-3 pb-md-5 text-center text-md-left">
              <p className="text-dark sans-serif mb-1 mt-4">
                Nephrite jade is a semi-precious gemstone with an inter-locking crystal structure. 
                This structure is responsible for it's incredible toughness and a host of other superior properties which are unique among all gemstones. 
                It is a stone that has been valued for over 10,000 years. <br/>
                We showcase nephrite's superior color, translucency, quality, strength, and versatility as un-engineered natural stone slabs.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-5 order-1 order-md-3 text-center text-md-left">
              <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
              <h4 className="serif text-dark font-italic">
                Jade is the fairest of stones.
                It is endowed with the five virtues:
                charity, rectitude, wisdom, courage, and equity.
              </h4>
              <hr className="col-1 horizontal-divider"></hr>
              <p className="d-none d-md-block text-dark text-right sans-serif">
                Definition of Jade<br/>
                Shuo-wen chieh-tzu 2nd-century<br/>
                CE Chinese Dictionary
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 px-0 text-center">
              <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
              <h4 className="serif text-light font-italic mb-4">
                Charity is typified by its luster; <br className="d-block d-sm-none" />bright yet warm.
              </h4>
              <hr className="col-1 horizontal-divider"></hr>
              <h1 className="text-white serif d-inline-block mb-0">COLOR AND DEPTH</h1>
              <p className="text-white sans-serif mt-4">
                Our nephrite slabs are available in endlessly varying shades of green; 
                from near-black to light green-blue. Each nephrite stone produces wholly unique patterns and characteristics.
              </p>
            </div>
          </div>
        </div>

        {/* TODO: Make hover-base smaller? */}
        <div className="black-background container-fluid pb-5">
          <div className="row justify-content-center">
            <GlassMagnifier
              className="img-fluid"
              imageSrc={hoverBase}
              largeImageSrc={hoverImage}
              cursorStyle="crosshair" 
              magnifierOffsetX={-100}
              magnifierOffsetY={100}
              magnifierBorderColor="white"
              magnifierSize='25%'
              magnifierBackgroundColor="#080f18"
              allowOverflow={true}
              touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP}
            />
          </div>
          <div className="row justify-content-center">
            <h5><IoIosAddCircle className="text-white align-text-top mr-2 rotate-ninety"/></h5>
            <h6 className="text-white sans-serif mb-0">
              HOVER FOR A CLOSER LOOK
            </h6>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center">
              <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
              <h4 className="serif text-dark font-italic mb-4">
                Rectitude is imbued by its translucency, revealing the color and markings within.
              </h4>
              <hr className="col-1 horizontal-divider"></hr>
              <h1 className="text-dark serif d-inline-block mb-0">TRANSLUCENY</h1>
              <p className="text-dark sans-serif mt-4">
                Nephrite's superior translucency is a result of its underlying crystal structure. 
                Light defuses within the material itself resulting in incredible depth and color, even in a thin profile.
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid pb-5">
          <div className="row justify-content-center">
            <div className="col-11 col-sm-6 col-md-5">
              <ReactCompareImage 
                leftImage={slideImageLeft}
                rightImage={slideImageRight}
              />
            </div>
          </div>
          <div className="row justify-content-center mt-2">
            <h5><MdSwapVerticalCircle className="text-white align-text-top mr-2 rotate-ninety"/></h5>
            <h6 className="text-white sans-serif mb-0">
              SLIDE FOR COMPARISON
            </h6>
          </div>
        </div>

        {/* TODO: Add Mineral Slider */}
        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center">
              <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
              <h4 className="serif text-dark font-italic mb-4">
                When the stone is struck; courage, in that it may be broken but cannot be bent.
              </h4>
              <hr className="col-1 horizontal-divider"></hr>
              { this.state.under576 ? 
                <h2 className="text-dark serif d-inline-block">DURABILITY AND TOUGHNESS</h2>
                :
                <h1 className="text-dark serif d-inline-block">DURABILITY AND TOUGHNESS</h1>
              }
              <p className="text-dark sans-serif my-4">
                Jade is the toughest of the gemstones, superior even to diamond (which can be quite brittle). 
                It has a crushing strength greater then steel. A gemstone's toughness is a measurement of its ability to withstand mechanical shock. 
                It differs from a stone's hardness, which is a material's ability to withstand wear and abrasion. 
              </p>
              <p className="text-dark sans-serif mb-4" >
                Nephrite has an impressive Mohs hardness of 6-7 which is roughly equivalent to that of quartz, and is resistant to scratching and staining.
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid">
          <div className="row justify-content-around p-5">
            <div className="col-12 col-md-8 col-xl-5 text-center text-xl-left px-0 ml-0 ml-xl-5">
              <h3 className="green-accent serif mb-0">&#x275B;&#x275B;</h3>
              <h4 className="text-white serif font-italic mb-5">Equity in that it has sharp angles which yet injure none.</h4>
              { this.state.under576 ? 
                <h2 className="text-white serif d-inline-block">PRODUCT SPECIFICATIONS</h2>
                :
                <h1 className="text-white serif d-inline-block">PRODUCT SPECIFICATIONS</h1>
              }
              <div className="d-flex justify-content-center justify-content-xl-start my-4">
                <p className={`text-white sans-serif mr-3 mr-sm-5 py-2 product-specifications-tab ${this.state.tab === 'dimensions' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('dimensions')} >Dimensions</p>
                <p className={`text-white sans-serif mx-3 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'thicknesses' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('thicknesses')} >Thicknesses</p>
                <p className={`text-white sans-serif mx-3 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'finishes' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('finishes')} >Finishes</p>
              </div>
              <div className={this.state.tab === 'dimensions' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mb-1 mt-4">
                  Vancouver Jade is the world's only supplier of nephrite dimensional stone blocks and slabs. 
                  Most semi-precious luxury stone slabs are resin-based aggregates, such as agate or turquoise slabs. 
                  We supply slabs of natural gemstone up to industry standard sizes for conventional dimensional stone.
                </p>
                <div className="d-flex justify-content-center justify-content-xl-start mt-5">
                  <div className="mr-5">
                    <h4 className="serif text-white">Maximum Width</h4>
                    <p className="green-accent sans-serif">190 m (75 inches)</p>
                  </div>
                  <div className="ml-5">
                    <h4 className="serif text-white">Maximum Length</h4>
                    <p className="green-accent sans-serif">275 m (108 inches)</p>
                  </div>
                </div>
              </div>
              {/* TODO: Add drag for different thicknesses */}
              <div className={this.state.tab === 'thicknesses' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mb-1 mt-4">
                  We offer pre-processing of thin section slabs to facilitate your jewelry processing needs as part of our lapidary services suite. 
                  Each slab is polished on one side for ease of material inspection and selection. 
                  We see this approach as the future of jade processing, eliminating the risk and subjectivity which is common place in the jade industry.
                </p>
                <div className="d-flex justify-content-center justify-content-xl-start mt-5">
                  <div>
                    <h4 className="serif text-white">Backlighting</h4>
                    <p className="green-accent sans-serif">&#60; 6.3 mm<br/>(0.25 inches)</p>
                  </div>
                </div>
              </div>
              <div className={this.state.tab === 'finishes' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mb-1 mt-4">
                  All of our materials are offered in a variety of finishes including:
                </p>
                <div className="d-flex justify-content-center justify-content-xl-start mt-5 mt-xl-4 mb-xl-5">
                  <div>
                    <h4 className="serif green-accent">Polished</h4>
                    <h4 className="serif green-accent">Honed</h4>
                    <h4 className="serif green-accent">Brushed</h4>
                    <h4 className="serif green-accent">Sand&nbsp;Blasted</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9 col-xl-5 align-self-center">
              <Img fluid={this.props.data.productSpecSlab.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-12 px-0 text-center">
              <h3 className="green-accent serif">&#x275B;&#x275B;</h3>
              <h4 className="serif text-dark font-italic mb-4">
                Wisdom; by the purity and penetrating quality of its note.
              </h4>
              <hr className="col-1 horizontal-divider"></hr>
            </div>
            { this.state.under576 ? 
              <h2 className="text-center text-md-left text-dark serif d-inline-block">MATERIAL CHARACTERISTICS</h2>
              :
              <h1 className="text-dark serif d-inline-block text-nowrap">MATERIAL CHARACTERISTICS</h1>
            }
          </div>
          <div className="row justify-content-center pb-0 pb-lg-5">
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4">Composition</h4>
              <p className="sans-serif text-dark small">Ca<sub>2</sub>(Mg,Fe)<sub>5</sub>Si<sub>8</sub>O<sub>22</sub>(OH)<sub>2</sub></p>
            </div>
            <div className="col-6 col-lg-2 hide-border-on-mobile pl-4">
              <h4 className="serif text-dark mb-4">Structure</h4>
              <p className="sans-serif text-dark small">Monoclinic,<br></br>Interlocking Crystals</p>
            </div>
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4 ">Hardness</h4>
              <p className="sans-serif text-dark small">6 - 6.5 Mohs Scale</p>
            </div>
            <div className="col-6 col-lg-2 px-4">
              <h4 className="serif text-dark mb-4">Diaphaneity</h4>
              <p className="sans-serif text-dark small">Translucent to Opaque</p>
            </div>
          </div>
          <div className="row justify-content-center pb-5">
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4">Friction Coefficient</h4>
              <p className="sans-serif text-dark small">0.74 -0.54<br/>(barefoot v Stocking)</p>
            </div>
            <div className="col-6 col-lg-2 hide-border-on-mobile px-4">
              <h4 className="serif text-dark mb-4">Elastic Modulus</h4>
              <p className="sans-serif text-dark small">130 GPa</p>
            </div>
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4">Damping</h4>
              <p className="sans-serif text-dark small">1 x 10<sup>-3</sup></p>
            </div>
            <div className="col-6 col-lg-2 px-4">
              <h4 className="serif text-dark mb-4">Fracture Strength</h4>
              <p className="sans-serif text-dark small">212 +/- 55 MPa</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    )
  }
};

export default SlabsAndSurfaces;
